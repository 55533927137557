.contactBanner {
    width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    background-image: url('/public/image/3.jpeg');
    background-size: cover;
    /* background-attachment: fixed; */
    background-position-y: calc(70%);
    background-repeat: no-repeat;
    color: var(--white);
    position: relative;
    z-index: 999;
}

.dataWrapper {
    width: 100%;
    background-color: #fbf5eb;
    padding: 80px 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    font-weight: 300;
}
.dataWrapper h1 {
    color: var(--main);
    padding-bottom: 30px;
    width: fit-content;
    margin: auto;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 1.1px;
}
.rapper {
    background-color: white;
    padding: 30px;
    width: 70%;
    margin: 20px 0px;
    margin: auto;
    font-size: 18px;
    letter-spacing: 0.1em;
}
.contactWrapper {
    background-color: white;
    padding: 30px;
    width: 70%;
    margin: 20px 0px;
    margin: auto;
    font-size: 18px;
    letter-spacing: 0.1em;
}
.coachingButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}
.coachingButtons a {
    background-color: var(--main);
    border-radius: 50px;
    color: white;
    padding: 10px;
    /* font-weight: 600; */
    border: 1px solid var(--main);
    transition: 0.3s;
}
.coachingButtons a:nth-child(1) {
    margin-right: 20px;
}
.coachingButtons a:hover {
}
@media (max-width: 800px) {
    .contactWrapper {
        width: 100%;
        padding: 20px;
    }
    .dataWrapper h1 {
        font-size: 32px;
    }
    .dataWrapper {
        padding: 20px 0px;
    }
    .contactBanner {
        background-attachment: unset;
    }
    .coachingButtons {
        flex-direction: column;
    }
    .coachingButtons a:nth-child(1) {
        margin-bottom: 10px;
        margin-right: 0px;
    }
    .coachingButtons a {
        font-size: 9px;
        font-weight: 600;
        padding: 10px 5px;
    }
}
