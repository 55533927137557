.contact {
    display: flex;
    background-color: #fbfafa;
    /* margin: 70px auto; */
    border-radius: var(--radius);
    display: grid;
    padding: 50px;
    grid-template-columns: 1fr 1fr;
}
.contact form {
    padding: 0px 40px;
    width: 100%;
    border-right: 1px solid #cdcdcd;
}
.advice {
    padding-left: 40px;
}
.contactBanner {
    width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50vh;

    background-image: url('/public/image/2.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position-y: bottom;
    background-repeat: no-repeat;
    color: var(--white);
    position: relative;
    gap: 10px;
    z-index: 999;
    /* 
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px; */
    margin-bottom: 40px;
}

.contact h2 {
    padding-bottom: 20px;
    font-weight: 600;
}
.contactOverlay {
    position: absolute;
    background-color: hsl(0, 0%, 0%, 0.2);
    height: 40vh;
    width: 100%;
    z-index: -1;
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px;
}
.contactBanner p {
    width: 50%;
    margin: 0px auto;
}
.contactBanner h2 {
    color: white;
    font-size: 40px;
    font-weight: 300;
}
.contact form h2 {
    /* font-size: 40px; */
    font-weight: 300;
    /* line-height: 1.8em; */
    letter-spacing: 1.1px;
    padding-bottom: 20px !important;
}
.checkBox {
    display: flex;
    align-items: center;
}
.checkBox label {
    padding-left: 5px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 1.1px;
}
.checkBox input {
    height: 35px;
    width: 35px !important;
}
.input {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}
.input label {
    color: var(--black);
    padding-left: 5px;
    font-size: 18px;
    font-weight: 600;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 1.1px;
}
.contact input,
textarea {
    outline: none;
    border: unset;
    border-bottom: 1px solid #cdcdcd !important;
    /* border-radius: 10px; */
    padding: 8px 10px;
    background-color: unset;
    width: 100%;
}
textarea {
    min-height: 250px;
    max-height: 250px;
    max-width: 100%;
    min-width: 100%;
}
.contact button {
    border: 1px solid var(--main);
    outline: none;
    color: var(--white);
    padding: 10px;
    width: 100%;
    cursor: pointer;
    transition: 0.5s;
    margin: 20px 0px;
}

.contact button {
    width: 200px;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 1.1px;
}
.blogIcon {
    padding-top: 250px;
    padding-bottom: 20px;
    font-size: 20px;
}
.error {
    font-size: 13px;
    color: rgb(255, 107, 107);
}

.contactIcon {
    display: flex;
    align-items: center;
}
.contactIcon div {
    width: 100%;
}
.main {
    color: var(--main) !important;
    padding-bottom: 20px !important;
}
.msg {
    color: var(--main);
    font-size: 16px !important;
}
.contactIcon img {
    width: 23px;
    margin-right: 10px;
}
.contactIcon {
    padding-bottom: 20px;
}
.contactIcon label {
    color: var(--main);
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 1.1px;
}

.contactIcon p {
    font-size: 20px;

    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 1.1px;
}

.formular {
    color: var(--main);
    font-size: 16px;
    font-weight: 600;
    transition: 0.3s;
    text-decoration: underline;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 1.1px;
}

.messageWrapper {
    width: 60%;
    max-width: fit-content;
    text-align: center;
    margin: auto;
    background-color: #fbfafa;
    padding: 50px;
    border-radius: 20px;
    margin: 40px auto;
    color: var(--main);
    font-weight: bold;
}
.iconDis {
    display: flex;
    align-items: center;
}
@media (max-width: 1400px) {
    .contact form {
        padding-left: 0px;
    }
    .contact {
        padding: 0px;
        padding-bottom: 20px;
    }
}
@media (max-width: 800px) {
    .contact {
        grid-template-columns: repeat(1, 1fr);
        padding: 0px;
        padding: 20px;
        margin-bottom: 20px;
    }
    .contact form {
        border-bottom: 1px solid #cdcdcd;
        border-right: unset;
        padding: 0px;
    }
    .advice {
        padding: 0px;
        padding-top: 10px;
    }
    .contactBanner {
        background-attachment: unset;
    }
    .contact input,
    textarea {
        font-size: 16px;
    }
    .iconDis p {
        font-size: 14px !important;
    }
    .main {
        font-size: 20px !important;
    }
}
