.orderList {
    list-style: circle;
    padding-left: 30px !important;
}
.orderList li {
    font-weight: 500;
    letter-spacing: 0em;
    font-size: 16px;
}
.contactBanner {
    display: flex;
    justify-content: center;
    background-color: #fbf5eb;
    height: 500px;

    padding-top: 40px;
}
.contactBanner img {
    height: 100%;
    border: 2px solid var(--main);
    border-radius: 20px;
}
.contactBanner {
    display: flex;
}

.dataWrapper {
    width: 100%;
    background-color: #fbf5eb;
    padding: 40px 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    font-weight: 300;
}
.dataWrapper h1 {
    color: var(--main);
    display: flex;
    width: fit-content;
    margin: auto;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 1.1px;
}
.contactWrapper {
    background-color: white;
    padding: 30px;
    width: 70%;
    margin: 20px 0px;
    margin: auto;
    font-size: 18px;
}
.contactWrapper h3 {
    font-weight: 500;
    letter-spacing: 0em;
    font-size: 16px;
}
.contactWrapper ul {
    font-size: 15px;
}
.coachingButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}
.coachingButtons a {
    background-color: var(--main);
    border-radius: 50px;
    color: white;
    padding: 10px;
    /* font-weight: 600; */
    border: 1px solid var(--main);
    transition: 0.3s;
}
.coachingButtons a:nth-child(1) {
    margin-right: 20px;
}
.coachingButtons a:hover {
}
@media (max-width: 800px) {
    .contactWrapper {
        width: 100%;
        padding: 20px;
    }
    .dataWrapper h1 {
        font-size: 32px;
    }
    .dataWrapper {
        padding: 20px 0px;
    }
    .contactBanner {
        background-attachment: unset;
        background-position-y: unset;
        padding-top: 0px;
        height: unset;
    }
    .contactBanner img {
        width: 100%;
        border-radius: 0px;
        border: unset;
        padding-top: 0px;
    }
    .coachingButtons {
        flex-direction: column;
    }
    .coachingButtons a:nth-child(1) {
        margin-bottom: 10px;
        margin-right: 0px;
    }
    .coachingButtons a {
        font-size: 9px;
        font-weight: 600;
        padding: 10px 5px;
    }
}
