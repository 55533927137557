.header {
    position: sticky;
    top: 0;
    z-index: 99999;
    width: 100%;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white; */
    padding: 10px 0px;
    background-color: white;
}
.logo {
    width: 100px;
}
.logo p {
    color: var(--main);
}
/* .logo img {
  width: 70px;
} */
.header ul {
    display: flex;
}
.header a {
    color: var(--main);
    font-weight: 600;
    transition: 0.3s;
}
.header a:hover {
}
.header ul li {
    padding: 0px 20px;
}
.mobileNav {
    display: none;
}
.mobileDisplay {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.mobileList {
    display: flex;
    align-items: center;
}
.mobileList li {
    list-style: none;
}
.mobileDisplay a {
    z-index: 3;
}
.hamburger-menu {
    width: 20%;
    z-index: 3;
}
.mobileDisplay a {
    color: white;
}
.mobileNav ul {
    text-align: left;
}
.mobileMenuContainer {
    background-color: var(--main);
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: -120%;

    transition: all 0.2s ease-in-out;
    -webkit-animation: movedown 0.5s linear forwards;
    -moz-animation: movedown 0.5s linear forwards;
    -ms-animation: movedown 0.5s linear forwards;
    -o-animation: movedown 0.5s linear forwards;
    animation: movedown 0.5s linear forwards;
    color: white;
    z-index: 2;
}

.mobileMenuContainer.opened {
    top: 67px;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
}
.mobileMenuContainer ul {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
}
.mobileMenuContainer a {
    font-size: 18px;
    font-weight: 400;
    color: white;
    text-align: left;
    animation: slidein 0.5s linear forwards;
    transition: all 0.2s ease-in-out;
}
.mobileHeaderData {
    display: flex;
    align-items: center;
    font-size: 13px;
}
.mobileBtn {
    display: block;
    text-align: center !important;
}
.mobileMenuContainer li {
    padding-bottom: 20px !important;
}
.hamburgerMenu {
    background-color: #fff;

    border-radius: var(--radius-input);
    color: var(--main);
    margin-left: 5px;
    font-size: 20px;
    border-radius: var(--radius-input);
    outline: none;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.5s;
    z-index: 4;
}
.language {
    position: relative;
}
.languageList {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 25px;
    cursor: pointer;
    background-color: var(--white);
    width: 100%;
    border-radius: var(--radius-input);
    display: none !important;
}
.languageListMob {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 25px;
    cursor: pointer;
    background-color: var(--white);
    border-radius: var(--radius-input);
    display: none !important;
    left: -55%;
    z-index: 999;
}
.language:hover .languageList,
.language:hover .languageListMob {
    display: block !important;
}
.languageList li,
.languageListMob li {
    border-bottom: 1px solid #efefef;
    border-radius: var(--radius-input);
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px !important;
}
.languageActive {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.languageList li:hover,
.languageListMob li:hover {
    background-color: #efefef;
}
.languageList img,
.languageListMob img,
.languageActive img {
    width: 25px;
    margin-right: 5px;
}
.navBtn {
    border: 2px solid var(--dark-main);
    padding: 15px;
    border-radius: 50px;
}
.desktopHeader ul {
    width: 60%;
    justify-content: flex-end;
}
.desktopHeader div {
    width: 20%;
}
.desktopHeader a{
    font-size: 16px !important;
}
.desktopHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.headerSignIn {
    display: flex;
    justify-content: flex-end;
}
.headerSignIn a:nth-last-child(1) {
    background-color: var(--main);
    color: white;
}
.headerSignIn a {
    padding: 10px 25px;

    transition: 0.3s;
    border-radius: 4px;
}
.headerSignIn a:nth-last-child(1):hover {
    background-color: #ffbbaa;
}
/* .desktopHeader div {
  width: 30%;
} */
@media (max-width: 1400px) {
    .desktopHeader ul {
        width: 60%;
    }
    .desktopHeader div {
        width: 40%;
    }
}
@media (max-width: 800px) {
    .mobileNav {
        display: block;
    }
    .desktopHeader {
        display: none;
    }
    .logo {
        width: 100%;
        padding: 0px;
    }
    .mobileMenuContainer a {
        font-size: 24px;
        font-weight: 800;
    }
    .logo img {
        width: 50px;
    }
}
