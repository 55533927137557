.about {
    background-color: var(--light-main);
    padding: 100px 0px;
}
.banner {
    width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    background-image: url('/public/image/coaching.jpeg');
    background-size: cover;
    background-attachment: fixed;
    background-position-y: bottom;
    background-repeat: no-repeat;
    color: var(--white);
    position: relative;
    gap: 10px;
    z-index: 999;
    text-align: center;
}
.overlayBanner {
    background-color: hsl(0, 0%, 0%, 0.2);
    position: absolute;
    width: 100%;
    padding: 50px;
    height: 50vh;
    top: 0;
    bottom: 0;
    z-index: -1;
}
.banner h1 {
    font-size: 50px;
    color: var(--main);
    font-weight: 200;
    line-height: 1.1em;
    letter-spacing: 1.1px;
}
.aboutWrapper {
    background-color: white;
    padding: 30px;
    width: 70%;
    margin: 20px 0px;
    margin: auto;
    font-size: 18px;
}
@media (max-width: 1400px) {
    .aboutWrapper {
        width: 100%;
    }
}
@media (max-width: 800px) {
    .about {
        padding: 50px 0px;
    }
    .banner {
        background-attachment: unset;
    }
}
