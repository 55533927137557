.banner {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  background-image: url("/public/image/3.jpeg");
  background-size: cover;
  /* background-attachment: fixed; */
  background-position-y: bottom;
  background-repeat: no-repeat;
  color: var(--white);
  position: relative;
  z-index: 999;

  /* border-bottom-left-radius: 80px;
  border-bottom-right-radius: 80px; */
}
.bannerContent {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.overlayBanner {
  background-color: hsl(0, 0%, 0%, 0.2);
  position: absolute;
  width: 100%;
  height: 70vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.banner h1 {
  font-size: 40px !important;
  color: var(--white);
  font-weight: bold;
  margin: 5px 0px;
  font-weight: 300;
  line-height: 1.2em;
  letter-spacing: 1.1px;
}
.banner p {
  font-size: 35px !important;
  font-weight: 200;
  color: white;
  font-weight: 300;
  line-height: 1.8em;
  letter-spacing: 1.1px;
}

/* .banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);

    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px;
} */

.parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 50px 0px;
  gap: 20px;
}
.parent > div {
  border-radius: var(--radius);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.parent > div:first-child {
  grid-area: 1 / 1 / 3 / 2;
  color: var(--main);
  background-color: var(--light-main);
}
.parent > div:nth-child(2) {
  grid-area: 1 / 2 / 3 / 3;
  color: #2e9079;
  background-color: #e6fbf6;
}
.parent > div:nth-child(3) {
  grid-area: 1 / 3 / 2 / 5;
}
.parent > :last-child {
  grid-area: 2 / 3 / 3 / 5;
  color: var(--main);
  background-color: var(--light-main);
}
.parent div > span {
  font-weight: bold;
}
.parent ul {
  display: flex;
  height: 80%;
  flex-direction: column;
  gap: 10px;
}

.aboutContent {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 300;
}
@media (max-width: 1400px) {
  .bannerContent {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .banner h1 {
    font-size: 36px;
  }
  .banner {
    padding: 20px;
  }
  .banner p {
    font-size: 32px;
  }
  .banner {
    background-attachment: unset;
  }
}
