.footer {
  background-color: var(--main);
  padding: 30px 0px;
  /* margin-top: 30px; */
}
.footerDis {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid white;
}
.footerDis h1 {
  color: white;
  font-weight: 300;
  line-height: 1.8em;
  letter-spacing: 1.1px;
}
.created {
  text-align: center;
  color: white;
  display: flex;
  width: 100% !important;
  justify-content: center;
  align-items: center;
}

.footerDis p,
.footerDis a {
  padding-bottom: 5px;
  color: white;
  font-weight: 600;
  padding: 10px 0px;
}
.footer img {
  height: 120px;
  border-radius: 100%;
}
.footerDis div {
  display: flex;
  flex-direction: column;
}
@media (max-width: 800px) {
  .footerDis {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
