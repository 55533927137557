.myMapCanva {
    width: 100%;
    height: 370px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boxInfo p,
.boxInfo a {
    padding: 0px;
}
.boxInfo a {
    color: var(--main);
    font-weight: bold;
}
.boxInfo {
    width: fit-content !important;
    position: absolute;
    background-color: var(--white);
    color: var(--black);
    margin: auto;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 140px;
    background-color: white;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 150px;
    -webkit-box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}
.boxInfo::after {
    background: #fff;
    -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    content: '';
    height: 12px;
    left: 0;
    position: absolute;
    /* top: -1px; */
    width: 25px;
    bottom: -5px;
    left: 43%;
}
