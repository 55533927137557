* {
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}
.google-map-html {
    font-size: 0.2px;
}
iframe .place-card,
iframe .place-card-large {
    display: none !important;
}
:root {
    --main: #e3ae59;

    /* --main: #581845;
    --main: #001f3f; */
    --light-main: #fff0d8;
    --black: #000;
    --white: #fff;
    --radius: 30px;
}
p,
h1,
h2 {
    margin: 0px;
    padding: 0px;
}
p {
    font-weight: 600;
    font-size: 20px !important;
    font-weight: 300;
}
ul li,
ol li {
    font-size: 16px !important;
    font-weight: 300;
    padding-bottom: 20px;
}
h2,
h1 {
    color: var(--main);
    padding: 0px !important;
    font-size: 30px !important;
}

body {
    padding: 0px;
    margin: 0px;
}

.container {
    width: 1200px;
    margin: auto;
}
ul,
ol {
    list-style: none;
    padding: 0px;
}
a {
    text-decoration: none;
    color: var(--black);
    padding: 20px 0px;
}
.link {
    width: fit-content;
    background-color: var(--main);
    border: 1px solid white;
    padding: 10px 20px;
    border-radius: var(--radius);
    font-weight: 500;
    margin: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    transition: 0.5s;
    font-weight: 600;
}

.section {
    padding: 40px 0px;
    width: 50%;
    margin: auto;
    text-align: center;
}
.section h2 {
    font-size: 30px;
    color: var(--main);
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 1.1px;
}
.section p {
    color: #010e23;
    letter-spacing: 1.1px;
    line-height: 1.8em;
}
.mianLoader {
    width: 100%;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 999999 !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.mianLoader div {
    margin: auto;
    width: 100px !important;

    height: 100% !important;
}
.body {
    position: relative;
}
.mobileIcon {
    display: none;
}
@media (max-width: 1200px) {
    .container {
        width: 100%;
        padding: 0px 20px;
    }
}

@media (max-width: 800px) {
    .section {
        width: 100%;
    }
    .mobileIcon {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        /* right: -75%; */
        position: fixed;
        left: 0;
        bottom: 50px;
        z-index: 999999999;
        width: fit-content;
        left: 76%;
    }
    .mobileIcon a {
        background-color: white;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        margin-bottom: 10px;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .mobileIcon img {
        height: 30px;
    }
    .link {
        width: 100%;
    }
    p {
        font-size: 16px !important;
    }
    ul li,
    ol li {
        font-size: 16px !important;
    }
    h2,
    h1 {
        font-size: 20px !important;
    }
}
