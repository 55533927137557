.containerWrapper {
    margin: 50px 0px !important;
}
.workDis a {
    border: 2px solid var(--main);
    position: relative;
    padding: 0px;
    height: 300px;
}
.workDis {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 90%;
    margin: auto;
}
.workDis img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}
.workDis p {
    background-color: hsl(37, 71%, 62%, 0.9);
    color: white;
    text-align: center;
    padding: 20px;
    font-size: 30px !important;
    font-weight: 300;
    line-height: 1.2em;
    letter-spacing: 1.1px;
    position: absolute;
    top: 0;
    bottom: 0;
    height: max-content;
    margin: auto;
    width: 350px;
    left: 0;
    right: 0;
    transition: 0.5s;
    height: 120px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

@media (max-width: 1400px) {
    .workDis {
        width: 100%;
    }
}
@media (max-width: 800px) {
    .workDis {
        grid-template-columns: repeat(1, 1fr);
    }
    .workDis p {
        width: 90%;
        height: 170px;
    }
}
