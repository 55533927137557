.aboutSection {
    background-color: #f9f8f8;
    width: 90%;
    /* border-radius: var(--radius); */
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* gap: 10px; */
    height: 600px;

    margin: 50px auto;
}

.experience p {
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 300;
}
.ptop{
    margin-top: 120px;
}

.aboutContentExpertise {
    margin: 20px 0px;
    background-color: var(--light-main);
    width: 100%;
    /* border-radius: var(--radius); */
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
    height: 450px;
}

.aboutSection img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    display: block;
    /* border-top-left-radius: var(--radius); */
    /* border-bottom-left-radius: var(--radius); */
}
.aboutContent {
    background-color: var(--main);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly !important;
}
.aboutContent p {
    color: white;
    font-size: 20px;
    font-weight: 300;
    padding-bottom: 20px;
}
.aboutContent h2 {
    color: white;
    font-size: 40px;
    font-weight: 300;
    padding-bottom: 50px;
}
.aboutContent a {
    color: var(--white);
}
.experience h2,
.biography h2 {
    font-size: 30px;
    padding: 20px 0px;
    line-height: 1.8em;
    letter-spacing: 1.1px;
}
.experience p {
    line-height: 1.8em;
    letter-spacing: 1.1px;
}
.aboutContent,
.more,
.biography p {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    letter-spacing: 1.1px;
    font-weight: 300;
    line-height: 1.8em;
}
.aboutContent,
.more {
    padding: 20px;
}

.more p {
    padding: 20px 0px;
    font-weight: 600;
}
.portrait {
    width: 80%;
    display: flex;
    align-items: center;
    gap: 20px;
    z-index: 9999;
}
.portrait p {
    font-weight: 500;
}
.portrait img {
    width: 300px;
    border-radius: var(--radius);
}
.imageWrapper {
    width: 50%;
}
.imageWrapper img {
    width: 100%;
}
@media (max-width: 1400px) {
    .aboutSection {
        width: 100%;
    }
    .imageWrapper {
        width: 100%;
    }
}
@media (max-width: 800px) {
    .aboutSection {
        grid-template-columns: repeat(1, 1fr);
    }
    .aboutSection img {
        width: 100%;
        height: auto;
    }
    .aboutSection {
        height: auto;
    }
    .experience h2 {
        font-size: 32px;
    }
}
