.dataWrapper p {
  padding: 10px 0px;
}
.dataWrapper h4{
  color: var(--main);
  margin: 5px 0px;
}
.dataWrapper h1{
  text-align: center;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}
.dataWrapper ul {
  list-style: circle;
  padding-left: 30px !important;

}
.dataWrapper {
  width: 100%;
  background-color: #fbf5eb;
  padding: 40px 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  font-weight: 300;
}

.contactWrapper {
  background-color: white;
  padding: 30px;
  width: 70%;
  margin: 20px 0px;
  margin: auto;
  font-size: 18px;
}

@media (max-width: 800px) {
  .contactWrapper {
      width: 100%;
      padding: 20px;
  }
  .dataWrapper h1 {
      font-size: 32px;
  }
  .dataWrapper {
      padding: 20px 0px;
  }
  .contactBanner {
      background-attachment: unset;
  }
  .coachingButtons {
      flex-direction: column;
  }
  .coachingButtons a:nth-child(1) {
      margin-bottom: 10px;
      margin-right: 0px;
  }
  .coachingButtons a {
      font-size: 9px;
      font-weight: 600;
      padding: 10px 5px;
  }
}
